@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNextCyr-Regular.eot');
    src: local('Avenir Next Regular'), local('AvenirNextCyr-Regular'),
        url('AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextCyr-Regular.woff2') format('woff2'),
        url('AvenirNextCyr-Regular.woff') format('woff'),
        url('AvenirNextCyr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNextCyr-Medium.eot');
    src: local('Avenir Next Medium'), local('AvenirNextCyr-Medium'),
        url('AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextCyr-Medium.woff2') format('woff2'),
        url('AvenirNextCyr-Medium.woff') format('woff'),
        url('AvenirNextCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNextCyr-Bold.eot');
    src: local('Avenir Next Bold'), local('AvenirNextCyr-Bold'),
        url('AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextCyr-Bold.woff2') format('woff2'),
        url('AvenirNextCyr-Bold.woff') format('woff'),
        url('AvenirNextCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNextCyr-Demi.eot');
    src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
        url('AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextCyr-Demi.woff2') format('woff2'),
        url('AvenirNextCyr-Demi.woff') format('woff'),
        url('AvenirNextCyr-Demi.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}